const { notification } = require("antd");


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: <p style={{ color: type === 'warning' ? "black" : "white" }}>{message}</p>,
        description: <div dangerouslySetInnerHTML={{ __html: description }} />,
        style: {
            backgroundColor: getBgColor(type),
            color: getTextColor(type),
        },
        closeIcon: (<div style={{ color: type === 'warning' ? "black" : "white" }}>X</div>)
    });
};

const getBgColor = (type) => {
    let color = ''
    if (type === 'success') {
        color = '#04d182'
        return color
    } else if (type === 'warning') {
        color = '#FEEFB3'
        return color
    } else {
        color = '#e43934'
        return color
    }
}


const getTextColor = (type) => {
    let color = ''
    if (type === 'success') {
        color = 'white'
        return color
    } else if (type === 'warning') {
        color = 'black'
        return color
    } else {
        color = 'white'
        return color
    }
}

const NotificationMessage = {
    openNotificationWithIcon,
}

export default NotificationMessage;