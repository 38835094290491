import React, { useEffect, useRef } from 'react';
import { APP_PREFIX_SETTING_PATH } from 'configs/AppConfig';
import { HOST_NAME, PROTOCOL } from 'configs/AppConfig';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	AlertTwoTone, UserOutlined
} from '@ant-design/icons';
import {
	WS_CONNECTION_STATUS,
	appendNotification,
	incrementUnreadNotificationsCount,
	setWsConnectionStatus,
} from 'store/slices/wsSlice';


const NotificationWS = () => {

	const { token } = useSelector((state) => state.auth);
	const message_alert_url = "/sound/Ding.wav"
	const message_alert = new Audio(message_alert_url)
	const maxRetries = useRef(5)
	const retriesCount = useRef(0)
	const dispatch = useDispatch();
	const client = useRef(null)
	const recieved_notifications = useRef([])
	const navigate = useNavigate();

	const displayNotification = (title, description = '', icon = null, url = null) => {
		const key = `message_notification_${Date.now()}`;
		notification.open({
			message: title,
			description: description,
			icon: icon,
			onClick: () => {
				if (url !== null) {
					navigate(url);
				}
				notification.close(key)
			},
			key,
			// duration: 0
		});
	};

	const updateWsConnectionStatus = (status) => {
		dispatch(setWsConnectionStatus(status))
	}

	const initClient = async () => {

		if (client.current !== null) {
			return
		}

		const protocol = PROTOCOL === 'https' ? 'wss' : 'ws'
		client.current = new W3CWebSocket(
			`${protocol}://${HOST_NAME}/ws/notifications/?token=${token}`
		);

		// console.log("CONNECTING...")
		updateWsConnectionStatus(WS_CONNECTION_STATUS.CONNECTING)

		client.current.onopen = () => {
			// @TODO: Display connected status
			updateWsConnectionStatus(WS_CONNECTION_STATUS.OPEN)
			retriesCount.current = 0
			// console.log("CONNECTED.")
		};

		client.current.onmessage = (message) => {
			const dataFromServer = JSON.parse(message.data);
			const action = dataFromServer.action
			if (action === 'notification_message') {
				const serverNotification = dataFromServer.message;
				if (recieved_notifications.current.includes(serverNotification.timestamp)) {
					// This specific notification has already been displayed
					return
				}
				recieved_notifications.current.push(serverNotification.timestamp)
				if (serverNotification) {
					const newNotification = {
						...serverNotification,
					}

					dispatch(appendNotification(newNotification))
					dispatch(incrementUnreadNotificationsCount())
					const url = `${APP_PREFIX_SETTING_PATH}/notifications`
					displayNotification(
						`${newNotification.sender.first_name} ${newNotification.sender.last_name}`,
						newNotification.title,
						(
							newNotification.sender.photo ?
								<Avatar
									src={newNotification.sender.photo}
									size='large'
								/> :
								<Avatar
									icon={
										newNotification.sender.first_name === 'System' ?
											<AlertTwoTone twoToneColor="grey" /> :
											<UserOutlined color="darkgrey" />
									}
									size='large'
								/>
						),
						url,
					)
					message_alert.volume = 0.5
					message_alert.play()
				}
			}
		};

		client.current.onclose = () => {
			try { updateWsConnectionStatus(WS_CONNECTION_STATUS.CLOSED) }
			catch { }

			(async () => {
				// console.log("CONNECTION INTERRUPTED.")
				if (retriesCount.current === -1) {
					// console.log("CONNECTION CLOSED.")
				}
				else if (retriesCount.current < maxRetries.current) {
					// console.log("RETRYING...")
					retriesCount.current += 1
					setTimeout(async () => {
						client.current = null
						await initClient()
					}, 3000);
				}
				else {
					// console.log("MAX RETRIES REACHED. CONNECTION FAILED.");
				}
			})();
		}
	}

	useEffect(() => {

		let close_client = true
		if (client.current === null) {
			initClient()
			close_client = false
		}

		return () => {
			if (client.current !== null && close_client) {
				retriesCount.current = -1
				client.current.close()
			}
		}

	}, [])

	return (
		<></>
	);
}

export default NotificationWS;