import React, { useEffect } from "react";
import { onBlankLayout, onSettingLayout } from "store/slices/themeSlice";
import { useDispatch } from "react-redux";
import NotificationWS from "components/NotificationWS";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));

    const isSettingLayout = props.settingLayout ? true : false;
    dispatch(onSettingLayout(isSettingLayout));
  }, [blankLayout, props.settingLayout]);

  return <>
    <Component {...props} /><NotificationWS />{/* <ChatWS /> */}</>;
};

export default AppRoute;
