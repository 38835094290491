import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'configs/AppConfig';
import AuthService from 'services/AuthService';


const token = JSON.parse(localStorage.getItem('token'))
export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    userInfo: {},
    token: token ? token : null,
}


//login user===============================================================
export const signIn = createAsyncThunk('auth/token/login',
    async ({ username, password }, thunkAPI) => {
        const response = await AuthService.login(username, password)
        if (response.status === 200) {
            const data = response.data;
            const user_data = await getUserInfo(data.auth_token);
            return { ...data, ...user_data }
        } else if (response.status === 400) {
            const message = response.data.non_field_errors[0]
            return thunkAPI.rejectWithValue(message || 'Error')
        } else {
            return thunkAPI.rejectWithValue('Internet Error')
        }
    });

//logout user======================================================================
export const signOut = createAsyncThunk('auth/token/logout',
    async (token) => {
        const response = await AuthService.logout(token);
        return response.data
    });

//logout user======================================================================

export const getUserInfo = async (token) => {
    return axios.get(`${BASE_URL}ekadmin/api/users/me/`, {
        headers: {
            Authorization: `token ${token}`
        }
    }).then(response => {
        if (response.status === 200) {

            const userInfo = {
                id: response.data.id,
                username: response.data.username,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                photo: response.data.photo,
                permissions: response.data.permissions,
            }
            return userInfo;
        }
    }).catch(error => { return error.response })
};

export const handleException = createAsyncThunk('handleException',
    async (error) => {
        return error
    });

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        showAuthMessage: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideAuthMessage: (state) => {
            state.message = ''
            state.showMessage = false
        },
        signOutSuccess: (state) => {
            state.message = 'You have been disconnected, please sign in again!!'
            state.showMessage = true
            state.loading = false
            state.token = null
            state.userInfo = null
            state.redirect = '/'
        },
        showLoading: (state) => {
            state.loading = true
        },
        updateUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        sendRequest: (state, action) => {
            state.loading = true
        },
        receiveResponse: (state, action) => {
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload.auth_token
                state.userInfo = {
                    id: action.payload.id,
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    photo: action.payload.photo,
                    username: action.payload.username,
                    permissions: action.payload.permissions,
                }
                state.message = ''
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false
                state.token = null
                state.userInfo = {}
                state.redirect = '/'
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(handleException.fulfilled, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
                state.token = null
                state.userInfo = null
                state.redirect = '/'
            })
            .addCase(handleException.rejected, (state) => {
                state.showMessage = false
            })
    },
})

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess,
    navigateBetweenPages,
    updateUserInfo,
    sendRequest,
    receiveResponse
} = authSlice.actions

export default authSlice.reducer