import { createSlice } from '@reduxjs/toolkit';

export const WS_CONNECTION_STATUS = {
    CONNECTING: "connecting",
    OPEN: "open",
    CLOSED: "closed",
}

export const initialState = {
    notifications: [],
    unreadNotificationsCount: 0,

    chatMessages: [],
    unreadMsgCount: 0,

    wsConnectionStatus: WS_CONNECTION_STATUS.CLOSED,
}

export const wsSlice = createSlice({
    name: 'ws',
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        appendNotification: (state, action) => {
            state.notifications = [action.payload, ...state.notifications]
        },
        setUnreadNotificationsCount: (state, action) => {
            state.unreadNotificationsCount = action.payload
        },
        incrementUnreadNotificationsCount: (state, action) => {
            state.unreadNotificationsCount += 1
        },
        setChatMessages: (state, action) => {
            state.chatMessages = action.payload
        },
        setUnreadMsgCount: (state, action) => {
            state.unreadMsgCount = action.payload
        },
        setWsConnectionStatus: (state, action) => {
            state.wsConnectionStatus = action.payload
        }
    },
})

export const {
    setNotifications,
    appendNotification,
    setUnreadNotificationsCount,
    incrementUnreadNotificationsCount,

    setChatMessages,
    setUnreadMsgCount,

    setWsConnectionStatus,
} = wsSlice.actions

export default wsSlice.reducer