/* import rootReducer from './rootReducer';
 */
import {configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice'
import wsReducer from './slices/wsSlice'
import themeReducer from './slices/themeSlice'
import storage from 'redux-persist/lib/storage'
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

const middlewares = [thunk];

const persistConfig = {
	key: "root",
	version: 1,
	storage
}

const reducer = combineReducers({
	auth: authReducer,
	ws: wsReducer,
	theme: themeReducer
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(middlewares)

	/* middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development', */
})

/* store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers));
	return store
} */



export default store