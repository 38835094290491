import axios from 'axios'
import { BASE_URL } from 'configs/AppConfig';
import EKAxios from 'utils/EKAxios';
import EKAxiosAuth from 'utils/EKAxiosAuth';


//user login================================================================
const login = async (username, password) => {
    return axios.post(BASE_URL + "auth/token/login/", { username, password, })
        .then((response) => {
            return response

        }).catch(error => { return error.response });
};

//user lgout
const logout = async () => {
    const response = EKAxios.post(BASE_URL + "auth/token/logout", {},)
        .then((response) => {
            if (response.status === 204) { }
            return response.data
        });
    // localStorage.clear()
    localStorage.removeItem("token")
    return response;
}


const register = async (userData) => {
    return EKAxiosAuth.post(BASE_URL + "auth/users/", userData)
        .then(response => response).catch(error => error);
};

const AuthService = {
    login,
    register,
    logout
}

export default AuthService;