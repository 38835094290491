import axios from "axios";
import store from "store";
import { signOutSuccess } from "store/slices/authSlice";
import { connect } from "react-redux";
import { APP_PREFIX_SETTING_PATH } from "configs/AppConfig";
import NotificationMessage from "./NotificationMessage";
import { message } from "antd";


const EKAxios = axios.create();

EKAxios.interceptors.request.use(config => {
    config.params = config.params || {};
    config.data = config.data || undefined;
    config.headers = config.headers || {};
    return config;
});

EKAxios.interceptors.response.use(
    response => {
        if (response.data.class !== undefined) {
            if (response.data.class === "lava.utils.Result") {
                const result = response.data.result;
                const message = response.data.message;
                if (result === 'success') {
                    NotificationMessage.openNotificationWithIcon('success', 'Success', message);
                } else if (result.result === "warning") {
                    NotificationMessage.openNotificationWithIcon('warning', 'Warning', message);
                } else if (result.result === "error") {
                    NotificationMessage.openNotificationWithIcon('error', 'Error', message);
                }
            }
        } else {
            return response;
        }
        return response;
    },
    error => {
        if (!error.response) {
            message.error('There was a problem connecting to the server. Please check your internet connection and try again.');
        }
        if (error.response.status === 400) {
            if (error.response.data.class !== undefined) {
                if (error.response.data.class === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', 'Error', message);
                }
            } else {
                if (error.response.data.name !== undefined) {
                    let message = error.response.data.name[0];
                    NotificationMessage.openNotificationWithIcon('error', 'Error', message);
                }
                return error.response;
            }
            return error.response;
        }

        if (error.response.status === 401) {
            if (error.response.data.class !== undefined) {
                if (error.response.data.class === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', 'Error', message);
                }
            } else {
                store.dispatch(signOutSuccess());
                window.location = "/auth/login/"
                return error.response;
            }
            return error.response;
        }

        if (error.response.status === 403) {
            if (error.response.data.class !== undefined) {
                if (error.response.data.class === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', 'Error', message);
                }
            } else {
                // store.dispatch(signOutSuccess());
                // window.location = "/auth/login/"
                const message = error.response.data.detail
                NotificationMessage.openNotificationWithIcon('error', 'Error', message);
                return error.response;
            }
            return error.response;
        }

        if (error.response.status === 404) {
            window.location = `${APP_PREFIX_SETTING_PATH}/notFoundPage`
            return error.response;
        }

        if (error.code === "ERR_NETWORK") {
            // network error
            return error.response;
        }

        if (error.code === "ERR_INTERNET_DISCONNECTED") {
            // network error
            return error.response;
        }

        if (error.response.status === 500) {
            if (error.response.data.class !== undefined) {
                if (error.response.data.class === "lava.utils.Result") {
                    const message = error.response.data.message;
                    NotificationMessage.openNotificationWithIcon('error', 'Error', message);
                }
            } else {
                const message = "An error occurred in the application. We are working hard to fix it as son as possible. We apologize for the inconvenience";
                NotificationMessage.openNotificationWithIcon('error', 'Error', message);
            }

            return error.response;
        }


        return Promise.reject(error);
    },
);

const mapStateToProps = ({ auth }) => {
    const { loading, message, showMessage, token, redirect } = auth;
    return { loading, message, showMessage, token, redirect }
}

export default connect(mapStateToProps)(EKAxios);
